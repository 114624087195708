// tslint:disable: object-literal-sort-keys
// import '@lottiefiles/lottie-player'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat'
import oneImg from './assets/images/one.svg'
import ThiefImg from './assets/images/thief.inline.svg'
import threeImg from './assets/images/three.svg'
import twoImg from './assets/images/two.svg'
import animationData from './assets/json/security.json'
import './styles.scss'

import LottieAnimation from '../../LottieAnimation/LottieAnimation'


const SecurityLevels = () => {
  return (
    <>
      <section className="py-layout-6">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <h1 className="text-primary">
                <FormattedMessage id="security.security_levels.title"/>
              </h1>
              <p className="mt-layout-2">
                <FormattedMessage id="security.security_levels.subtitle"
                  values={defaultFormatValues}
                />
              </p>
            </div>
          </div>

          <ThiefImg className="img-fluid mt-layout-4"/>

        </div>
      </section>

      <section className="security-levels mt-layout-7" id="securitySteps">
        <div className="container">
          <div className="security-levels__sticky-animation-mobile d-block d-lg-none">
            <div className="animation-wrapper">
              <LottieAnimation
                animationData={animationData}
                interactivityOptions={{
                  containerId: '#securitySteps',
                  mode: 'scroll',
                  actions: [
                  {
                    visibility: [0, .2],
                    type: 'seek',
                    frames: [0, 48],
                  },
                  {
                    visibility: [.2, .45],
                    type: 'loop',
                    frames: [48, 148],
                  },
                  {
                    visibility: [.45, .55],
                    type: 'seek',
                    frames: [148, 226],
                  },
                  {
                    visibility: [.55, .7],
                    type: 'loop',
                    frames: [226, 296],
                  },
                  {
                    visibility: [.7, .75],
                    type: 'seek',
                    frames: [296, 359],
                  },
                  {
                    visibility: [.75, 1],
                    type: 'loop',
                    frames: [359, 507],
                  },
                ]}}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="py-layout-5">
                <div className="d-flex align-items-center">
                  <img className="security-levels__header-number" src={oneImg}/>
                  <h1 className="step-header">
                    <FormattedMessage id="security.security_levels.data_transfer.title"/>
                  </h1>
                </div>

                <p className="lead text-dark-gray-3 mt-layout-3">
                  <FormattedMessage
                    id="security.security_levels.data_transfer.subtitle"
                    values={defaultFormatValues}
                  />
                </p>

                <h4 className="text-primary mt-layout-4 mb-layout-2">
                  <FormattedMessage id="security.security_levels.data_transfer.pci.title" />
                </h4>

                <p>
                  <FormattedMessage
                    id="security.security_levels.data_transfer.pci.body"
                    values={defaultFormatValues}
                  />
                </p>

                <h4 className="text-primary mt-layout-4 mb-layout-2">
                  <FormattedMessage id="security.security_levels.data_transfer.tokenization.title" />
                </h4>

                <p>
                  <FormattedMessage
                    id="security.security_levels.data_transfer.tokenization.body"
                    values={defaultFormatValues}
                  />
                </p>

                <h4 className="text-primary mt-layout-4 mb-layout-2">
                  <FormattedMessage id="security.security_levels.data_transfer.vault.title" />
                </h4>

                <p>
                  <FormattedMessage
                    id="security.security_levels.data_transfer.vault.body"
                    values={defaultFormatValues}
                  />
                </p>
              </div>

              <div className="py-layout-5">
                <div className="d-flex align-items-center">
                  <img className="security-levels__header-number" src={twoImg}/>
                  <h1 className="step-header">
                    <FormattedMessage id="security.security_levels.fraud_prevention.title"/>
                  </h1>
                </div>

                <p className="lead text-dark-gray-3 mt-layout-3">
                  <FormattedMessage
                    id="security.security_levels.fraud_prevention.subtitle"
                    values={defaultFormatValues}
                  />
                </p>

                <h4 className="text-primary mt-layout-4 mb-layout-2">
                  <FormattedMessage id="security.security_levels.fraud_prevention.machine_learning.title" />
                </h4>

                <p>
                  <FormattedMessage
                    id="security.security_levels.fraud_prevention.machine_learning.body"
                    values={defaultFormatValues}
                  />
                </p>

                <h4 className="text-primary mt-layout-4 mb-layout-2">
                  <FormattedMessage id="security.security_levels.fraud_prevention.scoring.title" />
                </h4>

                <p>
                  <FormattedMessage
                    id="security.security_levels.fraud_prevention.scoring.body"
                    values={defaultFormatValues}
                  />
                </p>
              </div>

              <div className="py-layout-5 mb-layout-4">
                <div className="d-flex align-items-center">
                  <img className="security-levels__header-number" src={threeImg}/>
                  <h1 className="step-header">
                    <FormattedMessage id="security.security_levels.auth.title"/>
                  </h1>
                </div>

                <p className="lead text-dark-gray-3 mt-layout-3">
                  <FormattedMessage
                    id="security.security_levels.auth.subtitle"
                    values={defaultFormatValues}
                  />
                </p>

                <h4 className="text-primary mt-layout-4 mb-layout-2">
                  <FormattedMessage id="security.security_levels.auth.double_factor.title" />
                </h4>

                <p>
                  <FormattedMessage
                    id="security.security_levels.auth.double_factor.body"
                    values={defaultFormatValues}
                  />
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="security-levels__sticky-animation d-none d-lg-block">
                <LottieAnimation
                  animationData={animationData}
                  interactivityOptions={{
                    containerId: '#securitySteps',
                    mode: 'scroll',
                    actions: [
                    {
                      visibility: [0, .2],
                      type: 'seek',
                      frames: [0, 48],
                    },
                    {
                      visibility: [.2, .45],
                      type: 'loop',
                      frames: [48, 148],
                    },
                    {
                      visibility: [.45, .55],
                      type: 'seek',
                      frames: [148, 226],
                    },
                    {
                      visibility: [.55, .7],
                      type: 'loop',
                      frames: [226, 296],
                    },
                    {
                      visibility: [.7, .75],
                      type: 'seek',
                      frames: [296, 359],
                    },
                    {
                      visibility: [.75, 1],
                      type: 'loop',
                      frames: [359, 507],
                    },
                  ]}}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SecurityLevels
