import React from 'react';
import { useIntl } from 'react-intl';

import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';

import LocaleContext from '../../contexts/LocaleContext';
import locales, { Locale } from '../../data/locales';

import bannerImg from './banner.png';

import { Location } from '@reach/router';

interface SEOProps {
  meta?: {
    content: string
    name: string
  }[]
  image?: string
  title?: string
  titleId?: string
  titleValues?: any
  description?: string
  descriptionId?: string
  descriptionValues?: any
  directImage?: boolean
  noTitleTemplate?: boolean
  articleAuthor?: string
  articleDate?: string
  timeToRead?: number
}

const SEO: React.FC<SEOProps> = ({
  meta,
  image,
  title,
  titleId,
  titleValues,
  description,
  descriptionId,
  descriptionValues,
  directImage,
  noTitleTemplate,
  articleAuthor,
  articleDate,
  timeToRead,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            siteName
          }
        }
      }
    `,
  );

  const intl = useIntl();

  const defaultTitle = intl.formatMessage({id: 'seo.title'});
  const defaultDescription = intl.formatMessage({id: 'seo.description'});

  const desc = descriptionId ? intl.formatMessage({id: descriptionId}, descriptionValues) : description;

  const metaDescription = desc ? desc : defaultDescription;
  let metaImage = image ? `${image}` : `${bannerImg}`;

  if(!directImage) {
    metaImage = `${site.siteMetadata.siteUrl}${metaImage}`
  }

  title = titleId ? intl.formatMessage({id: titleId}, titleValues) : title;

  let metaTitle = title;
  if (!noTitleTemplate) {
    metaTitle = `${title ? `${title} | ` : ''}${defaultTitle}`;
  }

  if(metaImage.startsWith('//')) {
    metaImage = `https:${metaImage}`
  }

  let metaTags = [
    {
      content: metaDescription,
      name: `description`,
    },
    {
      content: site.siteMetadata.siteName,
      name: `og:site_name`,
    },
    {
      content: metaImage,
      name: 'image',
    },
    {
      content: metaImage,
      property: 'og:image',
    },
    {
      content: metaTitle,
      property: `og:title`,
    },
    {
      content: metaDescription,
      property: `og:description`,
    },
    {
      content: `website`,
      property: `og:type`,
    },
    {
      content: `summary`,
      name: `twitter:card`,
    },
    {
      content: `Kushki`,
      name: `twitter:creator`,
    },
    {
      content: title,
      name: `twitter:title`,
    },
    {
      content: metaDescription,
      name: `twitter:description`,
    },
    {
      content: metaImage,
      name: `twitter:image`,
    },
    {
      content: articleDate,
      name: `article:published_time`,
    },
    {
      content: articleAuthor,
      name: `article:author`
    },
  ];

  if (meta) {
    metaTags = metaTags.concat(meta);
  }

  if (timeToRead) {
    metaTags = metaTags.concat([
      {
        content: intl.formatMessage({id: 'seo.time_to_read'}),
        name: 'twitter:label1'
      },
      {
        content: intl.formatMessage({id: 'seo.minutes_to_read'}, {minutes: timeToRead}),
        name: 'twitter:data1'
      },
    ])
  }

  const getLocalizedPath = (locale: Locale, path: string, selectedLocaleCode: string) => {
    if(path.includes(`/${selectedLocaleCode}`)) {
      path = path.replace(`/${selectedLocaleCode}`, '');
    }

    return locale.default ? `${site.siteMetadata.siteUrl}${path}` : `${site.siteMetadata.siteUrl}/${locale.code}${path}`;
  };

  return (
    <Location>
      {({ location }) => (
        <LocaleContext.Consumer>
          {localeCode =>
            <Helmet
              htmlAttributes={{
                lang: localeCode,
              }}
              title={metaTitle}
              meta={metaTags}
            >
              {
                locales.map(locale => {
                  return locale.code !== localeCode
                  ? <link
                      rel="canonical"
                      href={getLocalizedPath(locale, location.pathname, localeCode)}
                      hrefLang={locale.code}
                      key={locale.code}
                    />
                  : null
                })
              }

            </Helmet>
          }
        </LocaleContext.Consumer>
      )
    }
    </Location>

  );
};

export default SEO;
