import React from 'react';
import { FormattedMessage } from 'react-intl';
import ArrowLink from '../../ArrowLink/ArrowLink';

import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss'

import './styles.scss';

import cardImg from './assets/images/card.svg';
import cashImg from './assets/images/cash.svg';
import smartlinkImg from './assets/images/smartlink.svg';
import transferImg from './assets/images/transfer.svg';

interface Payment {
  description: string;
  icon: any;
  id: PaymentType;
  link: string;
  title: string;
}

type PaymentType = 'card' | 'transfer' | 'cash' | 'smartlink' | 'cajita' | 'subscriptions';

interface PaymentsCarouselProps {
  list: PaymentType[];
}

const payments: Payment[] = [
  {
    description: 'payments.card.description',
    icon: cardImg,
    id: 'card',
    link: '/payments/card',
    title: 'payments.card.title',
  },
  {
    description: 'payments.transfer.description',
    icon: transferImg,
    id: 'transfer',
    link: '/payments/transfer',
    title: 'payments.transfer.title',
  },
  {
    description: 'smartlinks.description',
    icon: smartlinkImg,
    id: 'smartlink',
    link: '/features/link-de-pago-smartlink',
    title: 'smartlinks.title',
  },
  {
    description: 'payments.cash.description',
    icon: cashImg,
    id: 'cash',
    link: '/payments/cash',
    title: 'payments.cash.title',
  },
]

const carouselParams = {
  grabCursor: true,
  slidesPerView: 'auto',
}

const PaymentsCarousel: React.FC<PaymentsCarouselProps> = ({ list }) => {
  return (
    <div className="payment-carousel-container">
      <Swiper {...carouselParams}>
        {
          payments
          .filter(payment => list.includes(payment.id))
          .map(payment =>
            <div className="payment-card" key={payment.id}>
              <img className="payment-card__icon" src={payment.icon} alt={payment.title}/>
              <div className="payment-card__content">
                <h4 className="text-primary mb-layout-3">
                  <FormattedMessage id={payment.title}/>
                </h4>
                <p className="text-dark-gray-2">
                  <FormattedMessage id={payment.description}/>
                </p>
                <ArrowLink to={payment.link} color="primary">
                  <FormattedMessage id="payments.carousel.link"/>
                </ArrowLink>
              </div>
            </div>
          )
        }
      </Swiper>
    </div>
)};

export default PaymentsCarousel;
