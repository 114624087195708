import React from 'react'
import { FormattedMessage } from 'react-intl'
import PaymentsCarousel from '../../Payments/PaymentsCarousel/PaymentsCarousel'
import SalesButton from '../../SalesButton/SalesButton'

import ctaImg from './assets/images/cta.svg';

import './styles.scss'

const SecurityCTA: React.FC = () => (
  <>
    <section className="pt-layout-7 pb-layout-5 security-payments-carousel">
      <div className="security-payments-carousel__bg"/>
      <div className="container">
        <PaymentsCarousel list={['card', 'transfer', 'cash', 'smartlink']} />
      </div>
    </section>

    <section className="security-cta">
      <div className="security-cta__bg"/>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <img className="img-fluid" src={ctaImg} />
          </div>
          <div className="col-md-6">
            <h2 className="mt-7 mt-md-0">
              <FormattedMessage id="security.cta.title"/>
            </h2>
            <div className="button-responsive-row mt-7">
              <SalesButton big={true} positionTag="bottom">
                <FormattedMessage id="buttons.sales"/>
              </SalesButton>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>
)

export default SecurityCTA
