import React from 'react';
import { FormattedMessage } from 'react-intl';

import IndexLayout from '../layouts';

import Footer from '../components/Footer/Footer';
import SecurityCTA from '../components/Security/SecurityCTA/SecurityCTA';
import SecurityHero from '../components/Security/SecurityHero/SecurityHero';
import SecurityLevels from '../components/Security/SecurityLevels/SecurityLevels';
import SEO from '../components/SEO/SEO';

import { LocaleTypes } from '../data/locales';

interface SecurityPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const SecurityPage: React.FC<SecurityPageProps> = ({ pathContext, location }) => {
  const content = () => (
    <>
      <SEO titleId="security.title" />

      <SecurityHero/>
      
      <SecurityLevels/>

      <SecurityCTA/>

      <Footer theme="dark" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default SecurityPage;
