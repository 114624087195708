// tslint:disable: object-literal-sort-keys
import { create } from '@lottiefiles/lottie-interactivity'
// import { create } from 'lottie-interactivity'
import lottie, { AnimationConfigWithData } from 'lottie-web';
import React, { useEffect, useRef } from 'react';

interface AnimationProps {
  animationData: any
  className?: string
  options?: AnimationConfigWithData
  interactivityOptions?: {
    containerId?: string
    actions: {
      visibility: number[]
      type: 'seek' | 'loop' | 'stop'
      frames: number[]
    }[]
    mode: 'scroll' | 'cursor'
  }
}

const LottieAnimation: React.FC<AnimationProps> = ({animationData, className, options, interactivityOptions}) => {
  const animationContainer = useRef<any>();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      animationData,
      renderer: 'svg',
      ...options,
      container: animationContainer.current,
    });

    if(interactivityOptions) {
      create({
        mode: interactivityOptions.mode || 'scroll',
        player: anim,
        container: interactivityOptions.containerId,
        actions: interactivityOptions.actions
      });
    }
  }, []);

  return (
    <div ref={animationContainer} className={className} />
  )
};

export default LottieAnimation;
